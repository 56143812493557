import React, { useState, useEffect } from "react";
import AnchorLink from "react-anchor-link-smooth-scroll";
import RegisterSection from "../components/chat-channel/ChannelHeader";
import { Tabs, Tab, TabPanel, TabList, TabProvider } from "react-web-tabs";
import "react-web-tabs/dist/react-web-tabs.css";
import RightImageWithContentFeature from "../components/chat-channel/RightImageWithContent";
import LeftImageWithContent from "../components/chat-channel/LeftImageWithContent";
import ArrowRightICon from "../components/common/Icons";
import TitleAndMetaTags from "../components/common/TitleAndHeader";
import { RequestForm } from "../components/form";
import Cards from "../components/chat-channel/Cards";
import useInterval from "../react/useInterval";
import { useSwipeable, Swipeable } from "react-swipeable";

const box = require("../assets/images/icons-web/box_1.svg");
const dropbox = require("../assets/images/icons-web/dropbox.svg");
const onedrive = require("../assets/images/icons-web/onedrive.svg");
const gdrive = require("../assets/images/icons-web/gdrive.svg");
const sharePoint = require("../assets/images/icons-web/sharepoint.svg");
const certify = require("../assets/images/icons-web/certify.svg");
const jumpcloud = require("../assets/images/icons-web/jumpcloud.svg");
const ad = require("../assets/images/icons-web/zure.svg");
const office365 = require("../assets/images/icons-web/ms_office.svg");
const okta = require("../assets/images/icons-web/okta.svg");
const onelogin = require("../assets/images/icons-web/onelogin.svg");
const bamboo = require("../assets/images/icons-web/bamboo.svg");
const orangehrm = require("../assets/images/icons-web/orangehrm.svg");
const zoho_people = require("../assets/images/icons-web/zoho_people_1.svg");
const gusto = require("../assets/images/icons-web/gusto.svg");
const sap = require("../assets/images/icons-web/sap_successfactors.svg");
const zoho_expense = require("../assets/images/icons-web/zoho_expense.svg");
const expensify = require("../assets/images/icons-web/expensify.svg");
const rydoo = require("../assets/images/icons-web/rydoo.svg");
const trip_actions = require("../assets/images/icons-web/trip_actions.svg");

const mobile = require("../assets/images/landing_page/customers_mob.png");
const section_two_mob = require("../assets/images/landing_page/hr_sec_1_mob.png");

const topImage = require("../assets/images/landing_page/hr_usecases.gif");
const MobImage = require("../assets/images/landing_page/hr_usecases_mob.gif");

const section_two = require("../assets/images/landing_page/hr_sec_1.png");
const section_three = require("../assets/images/landing_page/it_workflow.png");
const section_four = require("../assets/images/landing_page/hr_sec_3.png");
const section_five = require("../assets/images/landing_page/slack_teams.png");

const noCoding = require("../assets/images/landing_page/bottom_icon_1.png");
const supportAutomation = require("../assets/images/landing_page/bottom_icon_2.png");
const boostIT = require("../assets/images/landing_page/bottom_icon_3.png");

const featureContent = [
  {
    header:
      "Add pre-built HR workflow automations to HR Chatbot from our marketplace",
    content:
      " Workativ comes with over 400+ pre-built workflow automations for popular apps in HR, Payroll, Time Management, Knowledge Management, Document Management and so on, that are ready to use from our workflow marketplace. No coding. ",
    content1: "Just download, connect, and go live instantly.",
    link: null,
    image: section_four,
    bgColor: "bg_landing_yellow",
    iD: "marketplace",
  },
  {
    header: "Deploy HR Chatbot on your Slack or Teams in few clicks",
    content:
      "Supporting employees on Slack or Teams have shown to really improve HR experience. Customers have seen an average of 98% CSAT in employee service experience. ",
    content2:
      "Delight your employees with always-on 24/7, follow-me, auto-resolution HR Chatbot, from the comfort of their favourite apps like Slack or Teams.",
    link: null,
    image: section_five,
    toDisplaySlackIcons: true,
    bgColor: "bg_landing_white",
    iD: "slack",
  },
];

const cardData = [
  {
    header: "Encryption",
    content:
      "AES 256 bit encryption with 1,024-bit key-strength for data at Rest and TLS encryption for data in transit.",
    image: noCoding,
  },
  {
    header: "Access Controls",
    content:
      "Workativ implements role-based access through IAM that enforces segregation of duties, two-factor authentication, and end-to-end audit trails, ensuring access is in accordance with security policy.",
    image: supportAutomation,
  },
  {
    header: "Connect On-prem Apps",
    content:
      "Workativ’s HR Chatbot can connect with your on-prem app using secure connector so you can create workflows to automate tasks without a hitch, be it on-prem or cloud.",
    image: boostIT,
  },
];

const SlackAndTeams = () => (
  <div className="chat_channels_trial">
    <div className="chat_channels_trial_btn">
      <img loading = "lazy"
        src={require("../assets/images/trial_landing/slack.png")}
        alt="slack"
      />
      Slack
    </div>
    <div className="chat_channels_trial_btn">
      <img loading = "lazy"
        src={require("../assets/images/trial_landing/teams.png")}
        alt="teams"
      />
      Teams
    </div>
  </div>
);
const headerMenu = [
  {
    menu: "HR Chatbot",
    href: "#customers",
    id: "customers",
    offset: null,
  },
  {
    menu: "HR Workflows",
    href: "#workflow",
    id: "workflow",
    offset: 450,
  },
  {
    menu: "Marketplace",
    href: "#marketplace",
    id: "marketplace",
    offset: 150,
  },
  {
    menu: "Slack & MS Teams",
    href: "#slack",
    id: "slack",
    offset: 150,
  },
  {
    menu: "App Integrations",
    href: "#integrations",
    id: "integrations",
    offset: 150,
  },
];

const OpenMenu = () => {
  const [isActive, setIsActive] = React.useState("Customers");

  const [showMenu, setShowMenu] = React.useState(false);
  const onClick = () => setShowMenu(!showMenu);
  return (
    <>
      <a
        className={
          !showMenu
            ? "navbar-brand js-scroll-trigger"
            : "navbar-brand js-scroll-trigger icon_open"
        }
        href="javascript:viod(0)"
      >
        <img 
          src={require("../assets/images/workativ-logo.png")}
          alt="Workativ"
        />
      </a>
      <span>
        <button
          className="navbar-toggler collapsed"
          type="button"
          data-toggle="collapse"
          data-target="#navbarResponsive"
          aria-controls="navbarResponsive"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span
            className={
              !showMenu
                ? "navbar-toggler-icon"
                : "navbar-toggler-icon icon_open"
            }
            onClick={onClick}
          >
            {!showMenu ? (
              <svg viewBox="0 -53 384 384">
                <path d="M368 154.668H16c-8.832 0-16-7.168-16-16s7.168-16 16-16h352c8.832 0 16 7.168 16 16s-7.168 16-16 16zm0 0M368 32H16C7.168 32 0 24.832 0 16S7.168 0 16 0h352c8.832 0 16 7.168 16 16s-7.168 16-16 16zm0 0M368 277.332H16c-8.832 0-16-7.168-16-16s7.168-16 16-16h352c8.832 0 16 7.168 16 16s-7.168 16-16 16zm0 0"></path>
              </svg>
            ) : (
              <svg
                x="0px"
                y="0px"
                viewBox="0 0 512.001 512.001"
                xmlSpace="preserve"
              >
                <path d="M284.286 256.002L506.143 34.144c7.811-7.811 7.811-20.475 0-28.285-7.811-7.81-20.475-7.811-28.285 0L256 227.717 34.143 5.859c-7.811-7.811-20.475-7.811-28.285 0-7.81 7.811-7.811 20.475 0 28.285l221.857 221.857L5.858 477.859c-7.811 7.811-7.811 20.475 0 28.285a19.938 19.938 0 0014.143 5.857 19.94 19.94 0 0014.143-5.857L256 284.287l221.857 221.857c3.905 3.905 9.024 5.857 14.143 5.857s10.237-1.952 14.143-5.857c7.811-7.811 7.811-20.475 0-28.285L284.286 256.002z"></path>
              </svg>
            )}
          </span>
        </button>
      </span>
      {showMenu ? (
        <div id="results" className="search-results">
          <div className="navbar-collapse" id="navbarResponsive">
            <ul className="navbar-nav ml-auto">
              {headerMenu.map((menu) => (
                <li className="nav-item" onClick={onClick}>
                  <AnchorLink
                    offset={() => 160}
                    href={menu.href}
                    className={
                      isActive === menu.menu
                        ? " nav-link js-scroll-trigger active"
                        : " nav-link js-scroll-trigger "
                    }
                    onClick={() => {
                      setIsActive(menu.menu);
                    }}
                  >
                    {" "}
                    {menu.menu}
                  </AnchorLink>
                </li>
              ))}
            </ul>
          </div>
        </div>
      ) : null}
    </>
  );
};

const Menu = () => (
  <div id="results" className="search-results">
    <div className="navbar-collapse" id="navbarResponsive">
      <ul className="navbar-nav ml-auto">
        <li className="nav-item">
          <AnchorLink
            className="nav-link js-scroll-trigger"
            offset={() => 120}
            href="#customers"
          >
            Customers
          </AnchorLink>
        </li>
        <li className="nav-item">
          <AnchorLink
            className="nav-link js-scroll-trigger"
            offset={() => 120}
            href="#chatbot"
          >
            IT Helpdesk Chatbot
          </AnchorLink>
        </li>
        <li className="nav-item">
          <AnchorLink
            className="nav-link js-scroll-trigger"
            offset={() => 120}
            href="#workflow"
          >
            IT Workflows
          </AnchorLink>
        </li>
        <li className="nav-item">
          <AnchorLink
            className="nav-link js-scroll-trigger"
            offset={() => 120}
            href="#marketplace"
          >
            Marketplace
          </AnchorLink>
        </li>
        <li className="nav-item">
          <AnchorLink
            className="nav-link js-scroll-trigger"
            offset={() => 120}
            href="#slack"
          >
            Slack & MS Teams
          </AnchorLink>
        </li>
        <li className="nav-item">
          <AnchorLink
            className="nav-link js-scroll-trigger"
            offset={() => 120}
            href="#integrations"
          >
            App Integrations
          </AnchorLink>
        </li>
      </ul>
    </div>
  </div>
);

export default function FeatureHomePage() {
  const [isActive, setIsActive] = React.useState("HR Chatbot");

  const slides = [
    {
      image: salary,
      title: "Salary Enquires",
    },
    {
      image: leave,
      title: "Apply Leave",
    },
    {
      image: pto,
      title: "PTO",
    },
    {
      image: expense,
      title: "Expense Claims",
    },
    {
      image: policy,
      title: "Policy Searches",
    },
    {
      image: onboarding,
      title: "Onboarding",
    },
    {
      image: offboarding,
      title: "Offboarding",
    },
  ];

  const slideWidth = 100;
  const handlers = useSwipeable({
    onSwipedLeft: () => {
      // console.log("decrementOffset")
      incrementOffset();
    },
    onSwipedRight: () => {
      // console.log("decrementOffset")
      decrementOffset();
    },
    preventDefaultTouchmoveEvent: true,
    trackMouse: true,
  });

  const total = slides.length; // the length of the slide array
  const enabled = true; // pauses/disables the player
  const useLoaded = false; // this allows for delayed loads like images or embeds
  const speed = 10000; // speed in milliseconds to show each slide
  const loop = true;

  const [offset, setOffset] = React.useState(0);
  const [items, setItems] = React.useState([]);
  function incrementOffset() {
    if (offset === total - 1) {
      setOffset(loop ? 0 : offset);
    } else {
      // console.log("setOffset", offset)
      setOffset(offset + 1);
    }
  }

  function decrementOffset() {
    if (offset === 0) {
      setOffset(loop ? total - 1 : offset);
    } else {
      setOffset(offset - 1);
    }
  }

  const loaded = useLoaded ? items.length === total : true;
  useInterval(() => {
    if (loaded && enabled && offset < total) {
      incrementOffset();
    }
  }, speed);

  const [isOpen, setOpen] = React.useState(false);

  const [header, setHeader] = useState("top_menu_sticky");

  const [dropdown, setDropdown] = useState("HR Chatbot");

  const listenScrollEvent = (e) => {
    if (window.scrollY < 73) {
      return setHeader("top_menu_sticky");
    } else if (window.scrollY > 70) {
      return setHeader("top_menu_sticky top_menu_sticky_fixed");
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", listenScrollEvent);

    return () => window.removeEventListener("scroll", listenScrollEvent);
  }, []);

  // console.log("offset", offset)

  // console.log("FeatureHomePage", offset)

  return (
    <>
      <TitleAndMetaTags
        robots="noindex, nofollow"
        googlebot="noindex, nofollow"
        keywords={[""]}
        ogImage={topImage}
        title="Best HR Chatbot with Conversational AI for Employee Support | Automate HR Support | Free Trial"
        description="Automate routine processes in HR with Conversational AI Chatbot. Transform employee experience with AI and automation powered support solutions."
      />
      <section className="landing_page_chatbot">
        <section className={header}>
          <section className="landing_bg container_trial integrations_container">
            <section className="header_landing_wrapper">
              <section className="header_landing header">
                <div className="desktop_menu_page">
                  <nav
                    className="navbar navbar-expand-lg landing_bg fixed-top"
                    id="mainNav"
                  >
                    <div className="container">
                      <a
                        className="navbar-brand js-scroll-trigger"
                        href="javascript:viod(0)"
                      >
                        <img 
                          src={require("../assets/images/workativ-logo.png")}
                          alt="Workativ"
                        />
                      </a>
                      <button
                        className="navbar-toggler collapsed"
                        type="button"
                        data-toggle="collapse"
                        data-target="#navbarResponsive"
                        aria-controls="navbarResponsive"
                        aria-expanded="false"
                        aria-label="Toggle navigation"
                      >
                        <span className="navbar-toggler-icon"></span>
                      </button>
                      <div
                        className="navbar-collapse collapse"
                        id="navbarResponsive"
                      >
                        <ul className="navbar-nav ml-auto">
                          {headerMenu.map((menu) => (
                            <li className="nav-item">
                              <AnchorLink
                                offset={() => 120}
                                href={menu.href}
                                className={
                                  isActive === menu.menu
                                    ? " nav-link js-scroll-trigger active"
                                    : " nav-link js-scroll-trigger "
                                }
                                onClick={() => {
                                  setIsActive(menu.menu);
                                }}
                              >
                                {" "}
                                {menu.menu}
                              </AnchorLink>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  </nav>
                </div>
                <div className="mobile_menu_page">
                  <nav
                    className="navbar navbar-expand-lg landing_bg fixed-top"
                    id="mainNav"
                  >
                    <div className="container">
                      <OpenMenu />
                    </div>
                  </nav>
                </div>
              </section>
            </section>
            <RegisterSection
              Image
              rightImage={topImage}
              rightImageMobile={MobImage}
              backgroundColor={"landing_bg"}
              altImage={" Deliver smarter employee experience with HR Chatbot"}
              iD={"chatbot"}
            >
              <RegisterSection.FormWithContent>
                <RegisterSection.MainHeader>
                  Deliver smarter employee experience with HR Chatbot
                </RegisterSection.MainHeader>
                <RegisterSection.SubHeader>
                  Workativ helps you automate most of your employee HR enquires
                  and requests using an advanced HR chatbot with in-built HR
                  workflow automation.{" "}
                </RegisterSection.SubHeader>
              </RegisterSection.FormWithContent>
            </RegisterSection>
            <section className="center_image_home">
              <div className="container">
                <div className="col-md-12">
                  <div className="row">
                    <div className="main-slider-left">
                      <h2>
                        Companies automating HR support <br></br> using
                        Workativ’s HR Chatbot
                      </h2>
                      <picture>
                        <source media="(max-width:768px)" srcSet={mobile} />
                        <img loading = "lazy"
                          src={require("../assets/images/landing_page/customers.png")}
                          alt="Companies automating employee support with Workativ’s IT Helpdesk Chatbot"
                        ></img>
                      </picture>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <section
              className="features_img_left pl-10-tage landing_page trial_page_img bg_trial_page bg_landing_green"
              id="customers"
            >
              <div className="container">
                <div className="row">
                  <div className="col-md-6 feature_page_content_left">
                    <h3>Cover a wide range of HR topics for your HR Chatbot</h3>
                    <p>
                      Using workativ’s platform, you can create, remove or
                      optimise new topics for HR chatbot easily based on your
                      requirement. Use our no-code canvas to create engaging
                      employee experiences.
                    </p>
                    <p className="second_p">
                      Go digital with HR Chatbot to deliver remote employee
                      support – faster, smarter, and easier.
                    </p>
                  </div>
                  <div className="col-md-6 feature_page_img_right ">
                    <picture>
                      <source
                        media="(max-width:768px)"
                        srcSet={section_two_mob}
                      />
                      <img loading = "lazy"
                        src={section_two}
                        alt="Cover a wide range of HR topics for your HR Chatbot"
                      ></img>
                    </picture>
                  </div>
                </div>
              </div>
            </section>
            <TabProvider defaultTab="vertical-tab-one" vertical>
              <section
                className="features_img_left pl-10-tage landing_page trial_page_img bg_landing_white"
                id="workflow"
              >
                <div className="container">
                  <div className="row">
                    <div className="col-md-6 feature_page_img_right mob_slider_pad_0">
                      <div className="desktop_tabs">
                        <TabList>
                          <Tab tabFor="vertical-tab-one">Salary Enquires</Tab>
                          <Tab tabFor="vertical-tab-two">Apply Leave</Tab>
                          <Tab tabFor="vertical-tab-three">PTO</Tab>
                          <Tab tabFor="vertical-tab-four">Expense Claims</Tab>
                          <Tab tabFor="vertical-tab-five">Policy Searches</Tab>
                          <Tab tabFor="vertical-tab-six">Onboarding</Tab>
                          <Tab tabFor="vertical-tab-seven">Offboarding</Tab>
                        </TabList>
                        <TabPanel tabId="vertical-tab-one">
                          <img loading = "lazy" src={salary} alt="Salary Enquires"></img>
                        </TabPanel>
                        <TabPanel tabId="vertical-tab-two">
                          <img loading = "lazy" src={leave} alt="Apply Leave"></img>
                        </TabPanel>
                        <TabPanel tabId="vertical-tab-three">
                          <img loading = "lazy" src={pto} alt="PTO"></img>
                        </TabPanel>
                        <TabPanel tabId="vertical-tab-four">
                          <img loading = "lazy" src={expense} alt="Expense Claims"></img>
                        </TabPanel>
                        <TabPanel tabId="vertical-tab-five">
                          <img loading = "lazy" src={policy} alt="Policy Searches"></img>
                        </TabPanel>
                        <TabPanel tabId="vertical-tab-six">
                          <img loading = "lazy" src={onboarding} alt="Onboarding"></img>
                        </TabPanel>
                        <TabPanel tabId="vertical-tab-seven">
                          <img loading = "lazy" src={offboarding} alt="Offboarding"></img>
                        </TabPanel>
                      </div>
                      <div className="slider_wrapper">
                        <div className="slider_container" {...handlers}>
                          <img loading = "lazy" src={slides[offset].image} alt="onboarding" />
                        </div>
                        <div className="controls_wrapper">
                          <span
                            className="controls_left"
                            onClick={(event) => {
                              event.stopPropagation();
                              event.preventDefault();
                              decrementOffset();
                            }}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                            >
                              <g
                                id="left_arrow"
                                transform="translate(-20 -1622)"
                              >
                                <circle
                                  id="Ellipse_254"
                                  data-name="Ellipse 254"
                                  cx="12"
                                  cy="12"
                                  r="12"
                                  transform="translate(20 1622)"
                                  fill="#fff"
                                />
                                <g
                                  id="Group_6414"
                                  data-name="Group 6414"
                                  transform="translate(-396 -258)"
                                >
                                  <g
                                    id="Group_6413"
                                    data-name="Group 6413"
                                    transform="translate(424 1886)"
                                  >
                                    <path
                                      id="Path_3332"
                                      data-name="Path 3332"
                                      d="M430.629,1898.239a.508.508,0,0,1-.344-.134l-6.119-5.609a.51.51,0,0,1,0-.752l6.119-5.609a.51.51,0,0,1,.689.752l-5.709,5.234,5.709,5.233a.51.51,0,0,1-.345.886Z"
                                      transform="translate(-424 -1886)"
                                      fill="#2c7357"
                                    />
                                  </g>
                                </g>
                              </g>
                            </svg>
                          </span>
                          <span>{slides[offset].title}</span>
                          <span
                            className="controls_right"
                            onClick={(event) => {
                              event.stopPropagation();
                              event.preventDefault();
                              incrementOffset();
                            }}
                          >
                            <svg
                              id="right_arrow"
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                            >
                              <circle
                                id="Ellipse_254"
                                data-name="Ellipse 254"
                                cx="12"
                                cy="12"
                                r="12"
                                fill="#fff"
                              />
                              <g
                                id="Group_6414"
                                data-name="Group 6414"
                                transform="translate(8.861 6)"
                              >
                                <g id="Group_6413" data-name="Group 6413">
                                  <path
                                    id="Path_3332"
                                    data-name="Path 3332"
                                    d="M424.51,1898.239a.508.508,0,0,0,.344-.134l6.119-5.609a.51.51,0,0,0,0-.752l-6.119-5.609a.51.51,0,0,0-.689.752l5.709,5.234-5.709,5.233a.51.51,0,0,0,.345.886Z"
                                    transform="translate(-424 -1886)"
                                    fill="#2c7357"
                                  />
                                </g>
                              </g>
                            </svg>
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6 feature_page_content_left">
                      <h2>
                        HR Chatbot + HR Workflow Automation in a single platform
                      </h2>
                      <p>
                        <p>
                          Workativ platform provides one-click integration of HR
                          Chatbot with your HR apps and workflows that can
                          streamline and automate employee issues & requests
                          such as{" "}
                          <>
                            {buttonMenu.map((menu) => (
                              <li className="button_list_li">
                                <span
                                  className={
                                    isActive === menu.tabName ? "" : ""
                                  }
                                  onClick={() => {
                                    setIsActive(menu.tabName);
                                  }}
                                >
                                  {" "}
                                  {menu.content && (
                                    <span className="pad_content">
                                      {menu.content}
                                    </span>
                                  )}
                                  {menu.tabFor1 ? (
                                    <Tab tabFor={menu.tabFor1}>
                                      {menu.tabName}
                                    </Tab>
                                  ) : null}
                                </span>
                                <span className="space_left"> , </span>
                              </li>
                            ))}
                          </>
                          exit feedbacks and much more.
                        </p>
                      </p>
                      <p className="second_p">
                        Save a ton of time and effort for your HR staff to focus
                        on high value initiates.
                      </p>
                      <div className="card_link_landing" />
                    </div>
                  </div>
                </div>
              </section>
            </TabProvider>

            {featureContent.map((feature, index) => {
              if (index % 2 !== 0) {
                return (
                  <LeftImageWithContent
                    image={feature.image}
                    bgColor={feature.bgColor}
                    iD={feature.iD}
                    altImage={feature.header}
                  >
                    <LeftImageWithContent.MainHeader>
                      {feature.header}
                    </LeftImageWithContent.MainHeader>
                    <LeftImageWithContent.SubHeader>
                      <p
                        dangerouslySetInnerHTML={{ __html: feature.content }}
                      />
                      {feature.content1 && (
                        <p
                          className="second_p"
                          dangerouslySetInnerHTML={{
                            __html: feature.content1,
                          }}
                        />
                      )}
                      {feature.content2 && (
                        <p
                          dangerouslySetInnerHTML={{
                            __html: feature.content2,
                          }}
                        />
                      )}
                    </LeftImageWithContent.SubHeader>
                    <LeftImageWithContent.Link>
                      <div className="card_link_landing">
                        {feature.link && (
                          <a href={feature.link} className="url_manipulation">
                            Learn more{" "}
                            <span className="arrow_svg_link">
                              <ArrowRightICon />
                            </span>
                          </a>
                        )}
                        {feature.toDisplaySlackIcons != undefined && (
                          <SlackAndTeams />
                        )}
                      </div>
                    </LeftImageWithContent.Link>
                  </LeftImageWithContent>
                );
              }
              return (
                <RightImageWithContentFeature
                  image={feature.image}
                  bgColor={feature.bgColor}
                  iD={feature.iD}
                  altImage={feature.header}
                >
                  <RightImageWithContentFeature.Header>
                    <h3>{feature.header}</h3>
                  </RightImageWithContentFeature.Header>
                  <RightImageWithContentFeature.SubHeader>
                    <p dangerouslySetInnerHTML={{ __html: feature.content }} />
                    {feature.content1 && (
                      <p
                        dangerouslySetInnerHTML={{ __html: feature.content1 }}
                      />
                    )}
                    {feature.content2 && (
                      <p
                        dangerouslySetInnerHTML={{ __html: feature.content2 }}
                      />
                    )}
                  </RightImageWithContentFeature.SubHeader>
                  <RightImageWithContentFeature.Link>
                    {feature.link && (
                      <div className="card_link_landing">
                        <a href={feature.url} className="url_manipulation">
                          {feature.link}
                          <span className="arrow_svg_link">
                            <ArrowRightICon />
                          </span>
                        </a>
                      </div>
                    )}
                    {feature.toDisplaySlackIcons != undefined && (
                      <SlackAndTeams />
                    )}
                  </RightImageWithContentFeature.Link>
                </RightImageWithContentFeature>
              );
            })}
            <AppsIntegrations />
            <section className="center_image_home cards_landing_page">
              <div className="container">
                <div className="col-md-12">
                  <div className="row">
                    <div className="main-slider-left">
                      <h3>Your data is yours and you control it</h3>
                      <p>
                        Workativ has implemented robust security processes and
                        controls that are in compliance with industry-leading
                        standards and regulations.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <Cards cards={cardData} />
            </section>
            <RequestForm isFooterForm={true} />
            <Footer />
          </section>
        </section>
      </section>
    </>
  );
}

function AppsIntegrations() {
  return (
    <section className="apps_landing_page" id={"integrations"}>
      <div className="container">
        <div className="apps_landing_header_wrapper">
          <div className="apps_landing_header">
            <h3>
              Connect HR Chatbot with 50+ apps, 400+ actions, and 20000+
              workflow automations instantly. No coding
            </h3>
          </div>
          <div className="apps_landing_cards_section">
            {myObj.map((apps) => (
              <div className="apps_landing_cards col-lg-3 col-md-6 col-12">
                <h5>{apps.header}</h5>
                <div className="apps_landing_cards_wrapper">
                  <ul>
                    {apps.userApps.map((apps) => (
                      <li>
                        <img loading = "lazy" src={apps.icon} alt={apps.appName} />
                        <span>{apps.appName}</span>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            ))}
          </div>
          <p className="landing_content_p">
            Also available Document Management, Expense Management, Email
            Management, SMS Management, Notifications, and Collaboration Apps.
          </p>
        </div>
      </div>
    </section>
  );
}

const myObj = [
  {
    header: "13+ HR Apps",
    userApps: [
      {
        icon: bamboo,
        appName: "BambooHR",
      },
      {
        icon: orangehrm,
        appName: "OrangeHRM",
      },
      {
        icon: zoho_people,
        appName: "Zoho People",
      },
      {
        icon: gusto,
        appName: "Gusto",
      },
      {
        icon: sap,
        appName: "SAP SuccessFactors",
      },
    ],
  },
  {
    header: "5+ Document Management Apps",
    userApps: [
      {
        icon: box,
        appName: "Box",
      },
      {
        icon: dropbox,
        appName: "Dropbox",
      },
      {
        icon: onedrive,
        appName: "Onedrive",
      },
      {
        icon: gdrive,
        appName: "Google Drive",
      },
      {
        icon: sharePoint,
        appName: "SharePoint",
      },
    ],
  },
  {
    header: "15+ Access Management Apps",
    userApps: [
      {
        icon: okta,
        appName: "Okta",
      },
      {
        icon: ad,
        appName: "Microsoft Azure AD",
      },
      {
        icon: jumpcloud,
        appName: "Jumpcloud",
      },
      {
        icon: office365,
        appName: "Microsoft Office 365",
      },
      {
        icon: onelogin,
        appName: "Onelogin",
      },
    ],
  },

  {
    header: "05+ Expense Management Apps",
    userApps: [
      {
        icon: zoho_expense,
        appName: "Zoho Expense",
      },
      {
        icon: expensify,
        appName: "Expensify",
      },
      {
        icon: rydoo,
        appName: "Rydoo",
      },
      {
        icon: trip_actions,
        appName: "Trip Actions",
      },
      {
        icon: certify,
        appName: "Certify Expense",
      },
    ],
  },
];

const expense = require("../assets/images/landing_page/expense.png");
const policy = require("../assets/images/landing_page/policy.png");
const offboarding = require("../assets/images/landing_page/offboarding.png");
const onboarding = require("../assets/images/landing_page/onboarding.png");
const leave = require("../assets/images/landing_page/leave.png");
const salary = require("../assets/images/landing_page/salary.png");
const pto = require("../assets/images/landing_page/pto.png");

function Footer() {
  return (
    <section className="footer_landing">
      <div className="container">
        <div className="footer_landing_row">
          <img loading = "lazy"
            src={require("../assets/images/workativ-logo.png")}
            alt="Workativ"
          />
          <div className="text-right sm-align-left">
            <p>
              <span>
                Workativ © <span>2021</span>. All rights reserved.
              </span>
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}

const buttonMenu = [
  {
    tabFor1: "vertical-tab-one",
    tabName: "salary enquires",
  },
  {
    tabFor1: "vertical-tab-two",
    tabName: "apply leave",
  },
  {
    tabFor1: "vertical-tab-three",
    tabName: "PTO",
    content: "or",
  },
  {
    tabFor1: "vertical-tab-four",
    tabName: "expense claims",
  },
  {
    tabFor1: "vertical-tab-five",
    tabName: "policy searches",
  },
  {
    tabFor1: "vertical-tab-six",
    tabName: "employee onboarding",
  },
  {
    tabFor1: "vertical-tab-seven",
    tabName: "employee offboarding",
    content: "or",
  },
];
